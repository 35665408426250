import React, {useState, useEffect} from "react";
import {
    BANNER_CIRCULAR_HEIGHT, BANNER_CIRCULAR_POSTER_HEIGHT, BANNER_CIRCULAR_POSTER_WIDTH, BANNER_CIRCULAR_WIDTH,
    TRANSITION_DURATION, TYPE_BANNER_BANNER_LARGE, TYPE_BANNER_CIRCULAR, TYPE_BANNER_POSTER
} from "../PlaylistConstants";
import {Link} from "react-router-dom";
import Carousel from "react-multi-carousel";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronRight} from '@fortawesome/free-solid-svg-icons'
import {URL_CONTENT} from "@components/Routes/Location";
import {responsivelarge, responsiveCircle} from '@detail/Pages/DetailPage/CarrouselStyle';
import iconTVG from '@app/Resources/Images/icono_TVG.png';
import {useTranslation} from 'react-i18next';
import defaultBannerCircular from '@app/Resources/Images/default_banner_circular.jpg';

import {NotificationContainer} from 'react-notifications';
import "react-multi-carousel/lib/styles.css";
import '@detail/Pages/DetailPage/CarrouselStyle.css';
import {evaluateItemClass} from "./utils";
import {TVG} from "../../../../../app/src/Utils/Constants";
import Spinner from 'react-bootstrap/Spinner';
import {useSelector} from "react-redux";
import {actionTypeContainer} from "../../../../../app/src/Utils/utils";

const CircularWidgetComponent = ({key, className, style, titulo, hasMoreContents, id, isSmall, typeBanner,
                                     playlist, playSpinner, clickCallback, controller, event, turnOffLoading, access,
                                titleColor}) => {

    const [ overOnCard, setOverOnCard ] = useState(false);
    const {t} = useTranslation();

    return (
        <div className="CarouselContainer" key={key}>
            <div className={"Playlist " + className} style={style}>
                <div className="Titulo" style={{display: "flex"}}>
                    <p style={{width: "auto", color: titleColor}}>{titulo}</p>
                    {hasMoreContents &&
                    <Link to={URL_CONTENT + "/widget/" + id}
                          style={{padding: "0 0 0 15px", display: "flex", width: "auto"}}
                          className="Titulo button-link-secondary">
                        <div className="ShowMoreText">{t('ficha.showmore')}</div>
                        <FontAwesomeIcon icon={faChevronRight} style={{margin: "10px 0 0 5px"}}/>
                    </Link>}
                </div>
                { playlist?.length > 0 &&
                <Carousel
                    swipeable={true}
                    draggable
                    showDots={false}
                    responsive={typeBanner.toUpperCase() === TYPE_BANNER_BANNER_LARGE ? responsivelarge : responsiveCircle}
                    infinite={true}
                    autoPlay={false}
                    keyBoardControl={false}
                    transitionDuration={TRANSITION_DURATION}
                    customTransition="transform 1000ms ease-in-out 0s"
                    containerClass={"carousel-container PlaylistCollection " + typeBanner}
                    dotListClass="custom-dot-list-style"
                    itemClass="CardContainer"
                    centerMode={false}
                    partialVisible={false}>
                    {playlist?.map((w, i) =>
                        <div className={evaluateItemClass({i, overOnCard,
                            playlist,itemClass:'PlaylistItemContainer'})} key={i}
                             onMouseEnter={()=> setOverOnCard(i)}
                             onMouseLeave={()=> setOverOnCard(false)}
                            >
                            <Link to={{
                                pathname: actionTypeContainer(access).ROUTE + "/" + w.id,
                                state: {
                                    url: w.video?.url,
                                    type: w.video?.type,
                                    id: w.id
                                }
                            }}>
                                <div
                                    className="PlaylistItem"
                                    onClick={clickCallback ? () => clickCallback(w.id) : ''}
                                    key={w.titulo + "-" + w.video?.url}>

                                    <div className="PlaylistCircularItemFooter">
                                        <div className="PlaylistCircularItemTitle">{w.title}</div>
                                    </div>

                                    {typeBanner && typeBanner.toUpperCase() === TYPE_BANNER_POSTER && (w.poster?.url || w.background?.url) &&
                                    <img className="PlaylistCircularItemImage"
                                         src={!!w.poster ?
                                             w.poster?.url :
                                             !!w.background ?
                                             w.background?.url :
                                                 <img className="PlaylistItemImage"
                                                      src={defaultBanner}
                                                      alt={w.title}/>
                                         }
                                         alt={w.title}/>
                                    }
                                    {w.tags?.size !== 0 && w.tags?.toArray().includes(TVG) &&
                                    <img className="iconTVGCircular" src={iconTVG} alt={"iconTVG"}/>}

                                    {typeBanner && typeBanner.toUpperCase() === TYPE_BANNER_CIRCULAR &&
                                    <img className="PlaylistCircularItemImage"
                                         src={!!w.banner ?
                                             w.banner?.url:
                                             !!w.background ?
                                             w.background?.url:
                                                 defaultBannerCircular}
                                         alt={w.title}/>}
                                </div>
                            </Link>
                        </div>)
                    }
                </Carousel>}
            </div>
            <div className={"Alert"}>
                <NotificationContainer/>
            </div>
            {
                playSpinner && <div className="SpinnerContainer">
                    <Spinner animation="border" variant="primary" />
                </div>
            }
        </div>
    )
};

export default CircularWidgetComponent;