import React, {useEffect, useState} from "react";
import "../BarraSuperior.css";
import { useTranslation } from "react-i18next";
import { user } from "@core";
import { URL_SEARCH } from "../../../../../Components/Routes/Location";

const MenuLanguagesComponent = ({ handleShowLanguagesMenu, controller, availableLanguages,showLanguagesMenu,renderMobileVersion }) => {
  const { t, i18n } = useTranslation();

  const [languageSelected, setLanguageSelected]  = useState(i18n.language);

  const JP = 'jp';

  const handleChangeLanguage = (l) => {
    setLanguageSelected(l);
    const ev = new user.events.UpdateLanguage(l);
    controller.dispatch(ev);
    handleShowLanguagesMenu();
  };
  const isSearchSection = () => {
    return window.location.href.includes(URL_SEARCH);
  };

  return (
    <div className={`${languageSelected === JP ? 'MenuDesplegable_JP' : 'MenuDesplegable'} ${showLanguagesMenu && 'active'} ${isSearchSection() && renderMobileVersion && 'belowTheSearch'}`}>
      {availableLanguages?.length > 0 &&
        availableLanguages.map((lang, i) => (
          <div
            key={i}
            className={"Button-Language " + (i18n.language && i18n.language.startsWith(lang) ? "Selected" : "")}
            onClick={() => handleChangeLanguage(lang)}
          >
            <span className={i18n.language==='jp' && 'verticalText'}>{t(`lang.min.${lang}`)}</span>
          </div>
        ))}
    </div>
  );
};

export default MenuLanguagesComponent;
