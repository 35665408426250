import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import InfoIcon from "@material-ui/icons/Info";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import { toArray } from "@components/Kotlin/KotlinUtils";
import { MULTITENANT_CONSTANTS, TENANT } from "../../../Resources/Multitenant/tenantConstants";
import { URL_PLAYER, URL_VIDEODETAIL } from "../../Routes/Location";
import { ACCESS_PLAY, PLAYLIST_URL_VALUE } from "../../../../../detail/src/Pages/DetailPage/PlaylistConstants";
import VideoType from "../../../../../detail/src/Pages/DetailPage/VideoType";
import VideoTag from "../../../../../detail/src/Pages/DetailPage/VideoTag";
import { actionTypeContainer } from "../../../Utils/utils";
import style from "./style.module.css";
const Slide = ({ w, setRef, getRef, defaultBanner, access, clickCallback }) => {
  const { t } = useTranslation();
  const limitOfTags = 4;

  return (
    <Link
      style={MULTITENANT_CONSTANTS[TENANT].fullWidth ? { cursor: "pointer" } : { cursor: "default" }}
      to={{
        pathname: URL_VIDEODETAIL + "/" + w.id,
        state: {
          url: w.video?.url,
          type: w.video?.type,
          id: w.id,
        },
      }}
    >
      <div className={style.playlistHighlightItem} key={w.id + "-" + w.video?.url}>
        {w.title !== " " && !MULTITENANT_CONSTANTS[TENANT].fullWidth && (
          <div className={style.overContainerHome}>
            <div className={style.textHome}>
              <div className={style.highlightInfoContainer}>
                {w.category && <span className={style.xenero}>{w.category}</span>}
                {w.publishDate && (
                  <span className={style.data}>{moment(w.publishDate, "YYYY-MM-DD").format("DD MMM YYYY")}</span>
                )}
                {w.seasons && <span className={style.temporadas}>{t("ficha.seasons", { seasons: w.seasons })}</span>}
                {w.rating && <span className={style.idade}>{t("ficha.rating", { rating: w.rating })}</span>}
              </div>
              <VideoType subtitle={w} />
              <div className={style.titleHighlight}>{w.title}</div>
              <div className={style.highlightButtonsContainer}>
                {w.video?.url && (
                  <Link
                    to={{
                      pathname: access === ACCESS_PLAY && URL_PLAYER + "/" + w.id,
                      state: {
                        url: w.video?.url,
                        type: w.video?.type,
                        id: w.id,
                      },
                    }}
                    className={style.buttonSeeVideo}
                    onClick={clickCallback ? () => clickCallback(w.id) : ""}
                  >
                    <PlayCircleOutlineIcon className={style.infoIcon} />
                    Ver Ahora
                  </Link>
                )}
                <Link
                  to={{
                    pathname: URL_VIDEODETAIL + "/" + w.id,
                    state: {
                      url: w.video?.url,
                      type: w.video?.type,
                      id: w.id,
                    },
                  }}
                  className={style.buttonSeeDetails}
                >
                  <InfoIcon id={style.infoIcon} />
                  Más información
                </Link>
              </div>
              {w.tags.length && (
                <div className={style.highlightTagsContainer}>
                  {MULTITENANT_CONSTANTS[TENANT].showVideoTags ? (
                    <>
                      <LocalOfferIcon className={style.tagIcon} />
                      {toArray(w.tags).map((t, index) => {
                        return index < limitOfTags ? (
                          <VideoTag tag={t} key={w.tag + "-" + index} />
                        ) : (
                          index === limitOfTags && "..."
                        );
                      })}
                    </>
                  ) : (
                    w.subtitle
                  )}
                </div>
              )}
              {w.video?.url && (
                <Link
                  to={{
                    pathname: actionTypeContainer(access).ROUTE + "/" + w.id,
                    state: {
                      url: w.video?.url,
                      type: w.video?.type,
                      id: w.id,
                    },
                  }}
                  className={style.buttonAccess}
                  onClick={clickCallback ? () => clickCallback(w.id) : ""}
                ></Link>
              )}
            </div>
          </div>
        )}
        {w.background?.url === null && w.poster?.url === null && (
          <div className={`${style.playlistHighlight} ${w.title !== " " && style.playlistHighlightCover}`}>
            <img
              className={style.playlistHighlight}
              src={defaultBanner}
              alt={w.title}
              ref={setRef(w.titulo + "-" + w.video?.url + PLAYLIST_URL_VALUE)}
            />
          </div>
        )}
        {(w.background?.url || w.banner?.url) && (
          <div
            className={`${style.playlistHighlight} ${
              !MULTITENANT_CONSTANTS[TENANT].fullWidth && style.playlistHighlightCover
            } ${style.playlistHighlightHover}`}
          >
            <img
              src={!!w.background ? w.background?.url : w.banner?.url}
              alt={w.title}
              ref={setRef(w.titulo + "-" + w.video?.url)}
              className={style[MULTITENANT_CONSTANTS[TENANT].classHeight]}
            />
          </div>
        )}
      </div>
    </Link>
  );
};

export default Slide;
