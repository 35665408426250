import React, {useEffect, useState} from "react";
import CarouselAuto from 'react-bootstrap/Carousel'
import "react-multi-carousel/lib/styles.css";
import '@detail/Pages/DetailPage/CarrouselStyle.css';
import Spinner from 'react-bootstrap/Spinner';
import {useSelector} from "react-redux";

import HighlightSlideComponent from "./HighlightSlideComponent";
import {MULTITENANT_CONSTANTS, TENANT} from "../../../../../app/src/Resources/Multitenant/tenantConstants";
import {SWITCHER_WIDTH} from "../../../../../app/src/Utils/Constants";


const HighlightWidgetComponent = ({playlist, rotateTime, setRef, getRef, defaultBanner,
                                      access, clickCallback, playSpinner}) => {

    const [renderMobileVersion, setRenderMobileVersion] = useState(false);

    const showIndicators = () => {
        return MULTITENANT_CONSTANTS[TENANT].showSlideHighlightIndicators;
    }
    const handleResize = () => {
        setRenderMobileVersion(window.innerWidth <= SWITCHER_WIDTH);
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [window.innerWidth])

    return (<>
        <div className="CarouselContainer Principal">
            {playlist?.length > 0 &&
            <CarouselAuto
                controls={true}
                interval={60000}
                indicators={ !renderMobileVersion && showIndicators()}>
                {playlist?.map((w) =>
                        <CarouselAuto.Item
                            interval={rotateTime} key={w.id}>
                            <HighlightSlideComponent w={w} setRef={setRef} getRef={getRef} defaultBanner={defaultBanner}
                                                     access={access} clickCallback={clickCallback}/>
                        </CarouselAuto.Item>
                )}
            </CarouselAuto>
            }
            {
                playSpinner && <div className="SpinnerContainer">
                    <Spinner animation="border" variant="primary" />
                </div>
            }

        </div>

    </>
    )
};

export default HighlightWidgetComponent;