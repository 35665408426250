import React from "react";
import { MULTITENANT_CONSTANTS, TENANT } from "../../../../Resources/Multitenant/tenantConstants";
import style from "./style.module.css";
import VideoRating from "../../../../../../detail/src/Pages/DetailPage/VideoRating";

function Rating({ data, subtitle }) {
  return (
    <section className={style.rating}>
      <VideoRating w={data} />
      {MULTITENANT_CONSTANTS[TENANT].showSubtitle && (
        <div className={style.playlistItemSubtitlePerRating}>
          <p className={style.subtitle}>{subtitle}</p>
        </div>
      )}
    </section>
  );
}

export default Rating;
