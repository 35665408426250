import React, {useState, useEffect} from "react";
import {
    BANNER_TEXT_HEIGHT,
    BANNER_TEXT_WIDTH,
    TRANSITION_DURATION,
    TYPE_BANNER_BANNER_LARGE,
    TYPE_BANNER_POSTER, TYPE_BANNER_TEXT
} from "../PlaylistConstants";
import {Link} from "react-router-dom";
import Carousel from "react-multi-carousel";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronRight} from '@fortawesome/free-solid-svg-icons'
import {URL_CONTENT, URL_VIDEODETAIL} from "@components/Routes/Location";
import {responsive, responsivelarge} from '@detail/Pages/DetailPage/CarrouselStyle';
import iconTVG from '@app/Resources/Images/icono_TVG.png';
import {useTranslation} from 'react-i18next';

import {NotificationContainer} from 'react-notifications';
import "react-multi-carousel/lib/styles.css";
import '@detail/Pages/DetailPage/CarrouselStyle.css';

import timeIcon from '@app/Resources/Images/Icon ionic-ios-time.svg';
import {TVG} from "../../../../../app/src/Utils/Constants";
import Spinner from 'react-bootstrap/Spinner';

const TextWidgetComponent = ({key, className, style, titulo, hasMoreContents, id, isSmall, typeBanner,
                                 playlist, playSpinner, clickCallback, titleColor}) => {

    const {t} = useTranslation();

    return (
        <div className="PlaylistContainer" key={key}>
            <div className={"Playlist " + className} style={style}>
                <div className="Titulo" style={{display: "flex"}}>
                    <p style={{width: "auto", color: titleColor}}>{titulo}</p>
                    {hasMoreContents &&
                    <Link to={URL_CONTENT + "/widget/" + id}
                          style={{padding: "0 0 0 15px", display: "flex", width: "auto"}}
                          className="Titulo button-link-secondary">
                        <div className="ShowMoreText">{t('ficha.showmore')}</div>
                        <FontAwesomeIcon icon={faChevronRight} style={{margin: "10px 0 0 5px"}}/>
                    </Link>}
                </div>
                { playlist?.length > 0 &&
                <Carousel
                    swipeable={true}
                    draggable={isSmall}
                    showDots={false}
                    responsive={typeBanner.toUpperCase() === TYPE_BANNER_BANNER_LARGE ? responsivelarge : responsive}
                    infinite={false}
                    autoPlay={false}
                    keyBoardControl={false}
                    transitionDuration={TRANSITION_DURATION}
                    customTransition="transform 1000ms ease-in-out 0s"
                    containerClass={"carousel-container PlaylistCollection " + typeBanner}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px CardContainer CardContainer-text"
                    centerMode={false}
                    partialVisible>
                    {playlist?.map((w, i) =>
                        <div className={i === 0 ? "PlaylistTextItemContainer FirstItem" :
                            i === playlist.length - 1 ? "PlaylistTextItemContainer LastItem" :
                                "PlaylistTextItemContainer"} key={'text'+ w.id}>
                            <Link to={{
                                pathname: URL_VIDEODETAIL + "/" + w.id,
                                state: {
                                    url: w.video?.url,
                                    type: w.video?.type,
                                    id: w.id
                                }
                            }}>
                                <div
                                    className="PlaylistTextItem"
                                    onClick={clickCallback ? () => clickCallback(w.id) : ''}
                                    key={w.id + "-" + w.video?.url}>

                                    {typeBanner && typeBanner.toUpperCase() === TYPE_BANNER_POSTER && (w.banner?.url || w.background?.url) &&
                                    <img className="PlaylistTextItemImage"
                                         src={!!w.banner ?
                                             w.banner?.url:
                                             w.background?.url}
                                         alt={w.title}/>
                                    }
                                    {w.tags?.size !== 0 && w.tags?.toArray().includes(TVG) &&
                                    <img className="iconTVG" src={iconTVG} alt={"iconTVG"}/>}

                                    {typeBanner && typeBanner.toUpperCase() === TYPE_BANNER_TEXT && (w.banner?.url || w.background?.url) &&
                                    <img className="PlaylistTextItemImage"
                                         src={!!w.banner ?
                                             w.banner?.url:
                                             w.background?.url}
                                         alt={w.title}/>}
                                </div>
                                <div className="PlaylistTextItemFooter">
                                    <div className={"PlaylistTextItemTitleContainer"}>
                                        <p className={"PlaylistTextItemTitle"}>{w.title}</p>
                                    </div>
                                    <p className={"PlaylistTextItemText"}>{w.subtitle}</p>
                                    {w?.publish_date && <div className={"PlayListTextItemTimeContainer"}>
                                        <img src={timeIcon} className={"PlaylistTextItemTimeIcon"}/>
                                        <p className={"PlaylistTextItemText"}>{w?.publish_date}</p>
                                    </div>}
                                </div>
                            </Link>
                        </div>)
                    }
                </Carousel>}
            </div>
            <div className={"Alert"}>
                <NotificationContainer/>
            </div>
            {
                playSpinner && <div className="SpinnerContainer">
                    <Spinner animation="border" variant="primary" />
                </div>
            }
        </div>
    )
};

export default TextWidgetComponent;