import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import AlertNotification from "@components/GUI/Alert/Alert";

import './Favorite.css';

function Favorite(props) {
    const [actualizado, setActualizado] = useState(null);
    const [isFavorite, setIsFavorite] = useState(null);
    const {t} = useTranslation();

    useEffect(() => {
        if (isFavorite != null && isFavorite !== props.isFavorite) {
            setActualizado(true);
        }
        setIsFavorite(props.isFavorite);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const removeFav = () => {
        setActualizado(false);
        props.id ? props.controller?.dispatch(new props.events.RemoveFromFavorites(props.id)) :
            props.controller?.dispatch(props.events?.RemoveFromFavorites)
    }

    const addFav = () => {
        setActualizado(false);
        props.id ? props.controller?.dispatch(new props.events.AddToFavorites(props.id)) :
            props.controller?.dispatch(props.events?.AddToFavorites)
    }

    return (
        <>
            {props.isFavorite &&
                <div type="button" className="FavButton"
                     onClick={() => removeFav()}>
                    <ion-icon name={"heart"} style={{color: "#dc3545"}}/>
                </div>
            }
            {!(props.isFavorite) &&
                <div type="button" className="FavButton "
                     onClick={() => addFav()}>
                    <ion-icon name="heart-outline"/>
                </div>
            }
            {actualizado &&
            <AlertNotification type="success" showtitle={false}
                               text={props.isFavorite ? t('ficha.successaddfavorite') : t('ficha.successremovefavorite')}
                               isPlaylist={props.isPlaylist}/>
            }
        </>
    );
}

export default Favorite;