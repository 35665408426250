import React, {useRef} from 'react';
import {atcb_action} from "add-to-calendar-button";
import "./CalendarButtonStyles.css";
import 'add-to-calendar-button/assets/css/atcb.css';
import {DEFAULT_TIME_ZONE} from "../../../../app/src/Utils/Constants";
import TodayIcon from '@material-ui/icons/Today';
const CalendarButtonComponent = ({detail}) => {
    const buttonRef = useRef();

    const name = detail.content?.title || "";
    const description = detail.content?.description || "";
    const startDate = !!detail.content?.startTimeMs ? new Date(detail.content?.startTimeMs) : new Date();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || DEFAULT_TIME_ZONE;

    const config = {
        name,
        description,
        startDate,
        options: ["Google", "iCal"],
        timeZone,
        trigger: "click",
        iCalFileName: name,
    };

    const handleAddToCalendar = () => {
        atcb_action(config, buttonRef.current);
    };

    return (
        <div ref={buttonRef} className="CalendarButton"
             onClick={() => handleAddToCalendar()}>
            <TodayIcon/>
        </div>
    );
};

export default CalendarButtonComponent;