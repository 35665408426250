import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import style from "./style.module.css";
import styleHover from "../PlaylistItem/style.module.css";

import { WIDGET_ACTION_PLAY } from "../../contanst";
import { useIsLogin, useTranslate } from "../../utils/utils";
import { URL_LOGIN } from "../../../Routes/Location";
import { MULTITENANT_CONSTANTS, TENANT } from "../../../../Resources/Multitenant/tenantConstants";
import { actionTypeContainer } from "../../../../Utils/utils";
import FavButton from "../FavButton/FavButton";

function ActionButtons({ data, access, event }) {
  const { id, canFav, isFav, video, controller } = data;
  const videoUrl = video?.url;
  const videoType = video?.type;
  const isLogin = useIsLogin();
  const t = useTranslate();
  return (
    <section
      className={`
      ${styleHover.playFavContainer__top}
      ${MULTITENANT_CONSTANTS[TENANT].ImageWithText ? style.playFavContainer : style.playFavContainer__top}`}
    >
      <Link
        to={{
          pathname:
            !isLogin && access === WIDGET_ACTION_PLAY ? URL_LOGIN : actionTypeContainer(access).ROUTE + "/" + id,
          state: {
            url: videoUrl,
            type: videoType,
            id: id,
          },
        }}
      >
        {!isLogin && access === WIDGET_ACTION_PLAY ? (
          <span className={style.linkSpace}>{t("ficha.login")}</span>
        ) : (
          <PlayCircleOutlineIcon id={style.playIcon} />
        )}
      </Link>

      {canFav && <FavButton className={style.favIcon} controller={controller} events={event} id={id} isFavorite={isFav} isPlaylist={true} />}
    </section>
  );
}

ActionButtons.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    canFav: PropTypes.bool.isRequired,
    isFav: PropTypes.bool.isRequired,
  }).isRequired,
  access: PropTypes.string.isRequired,
};

export default ActionButtons;
