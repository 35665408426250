import React, { useContext, useEffect, useState } from "react";
import "../../Menu.css";
import { menu, home } from "@core";
import { Notifications } from "../../Context/Notifications";
import MenuSectionsComponent from "./components/MenuSectionsComponent";
import UserSectionsComponent from "./components/UserSectionsComponent";
import LateralMenu from "./components/LateralMenu";
import { URL_LOGIN } from "../../../../Components/Routes/Location";
import { useKeycloak } from "@react-keycloak/web";
import NotificationMenuComponent from "./components/NotificationMenuComponent";
import {
  CONFIGURATION_SECTION,
  LOGOUT_SECTION,
  MENU_EXCLUDED_SECTIONS_BY_ID,
  RANKING_SECTION,
  STUDIO_SECTION,
} from "../../MenuConstants";
import useOutput from "../../../../Utils/Hooks/useOutput";
import { ACTION_GAME } from "../../../../../../home/src/Pages/Home/HomeComponentConstants";
import { setAchievementsAndRewardsInLocalStorage } from "../../../../Utils/utils";
import { User } from "../../../../../../user/src/Context/User";
import { useHistory } from "react-router-dom";
import iconCeltaDeVigo from "@app/Resources/Images/Celta_de_Vigo.ico";
import iconDiputacion from "@app/Resources/Images/dc_negativo.png";

import { MULTITENANT_CONSTANTS, TENANT } from "../../../../Resources/Multitenant/tenantConstants";

const MenuComponent = ({
  localSectionSelected,
  sections,
  controller,
  homeParentController,
  studioUrl,
  activatedNotifications,
  handleToggleNotifications,
  renderMobileVersion,
  handleChangeSection,
}) => {
  const outputFn = useOutput();
  const { keycloak } = useKeycloak();
  const history = useHistory();

  const [showMenu, setShowMenu] = useState(false);
  const [displayNotifications, setDisplayNotifications] = useState(false);
  const [homeController, setHomeController] = useState(null);
  const [numberNewNotifications, setNumberNewNotifications] = useState(0);

  const CELTA_DE_VIGO = "cinfo-celta";
  const DIPUTACION = "cinfo-deputacion";

  const { notifications } = useContext(Notifications);
  const { userName } = useContext(User);

  useEffect(() => {
    let con = homeParentController ? homeParentController : new home.controller();
    let binding = con.bind(model, (o) => outputFn(o, output, home));
    setHomeController(con);

    return () => con.unbind(binding);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNumberNewNotifications(getNumberNewNotifications());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  const model = () => { };

  const output = (o) => {
    if (o.constructor.$metadata$.simpleName === ACTION_GAME) {
      setAchievementsAndRewardsInLocalStorage(o);
    }
  };

  const changeSection = (s) => {
    const ev = new menu.events.SelectSection(s);
    controller.dispatch(ev);
    setShowMenu(false);
    handleChangeSection(s.iconId);
  };

  const changeDynamicSection = (section) => {
    localStorage.setItem("OTT-SectionID", section.screenId);
    const ev = new home.events.LoadScreen(section.screenId);
    homeController.dispatch(ev);
    setShowMenu(false);
    handleChangeSection(section.iconId);
  };

  const displayMenu = () => {
    if (showMenu) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
    if (displayNotifications) {
      setDisplayNotifications(false);
    }
  };

  const onContainerClick = (event) => {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      setShowMenu(false);
    }
  };

  const logout = () => {
    let ev = menu.events.Logout;
    controller.dispatch(ev);
    history.push(URL_LOGIN);
    keycloak.logout();
  };

  const handleToggleNotificationMenu = () => {
    setDisplayNotifications((displayNotifications) => !displayNotifications);

    if (showMenu) {
      setShowMenu(false);
    }
  };

  const handleCloseNotificationMenu = (event) => {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      setDisplayNotifications(false);
    }
  };

  const studioSection = studioUrl ? STUDIO_SECTION(studioUrl) : false;
  const configurationSection = CONFIGURATION_SECTION;
  const logOutSection = LOGOUT_SECTION;
  const rankingSection = RANKING_SECTION;

  const getNumberNewNotifications = () => {
    const newNotifications = notifications ? notifications.filter((notification) => notification.isNew) : [];
    return newNotifications.length > 0 ? newNotifications.length : 0;
  };

  const getClassName = () => {
    return numberNewNotifications > 0 ? "NotificationsContainer Signal" : "NotificationsContainer";
  };

  const getIconSection = (iconId) => {
    const iconSection =
      iconId === CELTA_DE_VIGO ? (
        <img src={iconCeltaDeVigo} alt={iconId} />
      ) : iconId === DIPUTACION ? (
        <img src={iconDiputacion} alt={iconId} />
      ) : (
        <ion-icon name={iconId} />
      );
    return iconSection;
  };

  const filteredSections = sections.filter((s) => !MENU_EXCLUDED_SECTIONS_BY_ID.includes(s.section.id.toUpperCase()));

  const [configurationIcon, setConfigurationIcon] = useState(<ion-icon name={configurationSection.section.iconId} />);
  const [logoutIcon, setLogoutIcon] = useState(
    <ion-icon name={logOutSection.section.iconId} class={MULTITENANT_CONSTANTS[TENANT].menuClassLogout} />,
  );

  useEffect(() => {
    if (configurationSection.section.imgIcon) setConfigurationIcon(configurationSection.section.imgIcon);
    if (logOutSection.section.imgIcon) setLogoutIcon(logOutSection.section.imgIcon);
  }, [configurationSection, logOutSection]);

  return (
    <div className="Menu">
      {showMenu ? (
        <LateralMenu
          onContainerClick={onContainerClick}
          displayMenu={displayMenu}
          sections={filteredSections}
          rankingSection={rankingSection}
          studioSection={studioSection}
          configurationSection={configurationSection}
          logOutSection={logOutSection}
          changeSection={changeSection}
          logout={logout}
          changeDynamicSection={changeDynamicSection}
          handleToggleNotificationMenu={handleToggleNotificationMenu}
          notifications={notifications}
          getIconSection={getIconSection}
          configurationIcon={configurationIcon}
          logoutIcon={logoutIcon}
        />
      ) : (
        <div className="MenuLateral">
          <div className={"MenuSection"} onClick={displayMenu}>
            <ion-icon name="menu" />
          </div>
          {filteredSections && (
            <div className={"MenuContainer"}>
              <MenuSectionsComponent
                localSectionSelected={localSectionSelected}
                handleChangeSection={handleChangeSection}
                sections={filteredSections}
                changeSection={changeSection}
                changeDynamicSection={changeDynamicSection}
                rankingSection={rankingSection}
                getIconSection={getIconSection}
              />
              <div>
                {notifications?.length > 0 && (
                  <div className={getClassName()} onClick={handleToggleNotificationMenu}>
                    <ion-icon name="notifications" />
                    {numberNewNotifications > 0 && <p className={"NotificationsNumber"}>{numberNewNotifications}</p>}
                  </div>
                )}
                {!!userName && (
                  <UserSectionsComponent
                    studioSection={studioSection}
                    configurationSection={configurationSection}
                    logOutSection={logOutSection}
                    changeSection={changeSection}
                    logout={logout}
                    configurationIcon={configurationIcon}
                    logoutIcon={logoutIcon}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      )}
      {displayNotifications && (
        <NotificationMenuComponent
          handleCloseNotificationMenu={handleCloseNotificationMenu}
          activatedNotifications={activatedNotifications}
          notifications={notifications}
          handleToggleNotifications={handleToggleNotifications}
          renderMobileVersion={renderMobileVersion}
        />
      )}
    </div>
  );
};

export default MenuComponent;
