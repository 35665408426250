import React, { useEffect, useState, useContext } from 'react';
import { Redirect, useHistory } from "react-router-dom";
import { getNextScreen, URL_HOME, URL_LOGIN } from '@components/Routes/Location';
import AlertNotification from '@components/GUI/Alert/Alert';
import Playlist from "@detail/Pages/DetailPage/Playlist";
import './HomeComponent.css';
import "react-multi-carousel/lib/styles.css";
import { User } from "@user/Context/User";
import { home, user } from '@core'
import { useDispatch } from "react-redux";
import {
    ACTION_GAME,
    WIDGET_NAME_GROUP_WIDGET
} from "./HomeComponentConstants";
import {
    displayOnBoardingActionCreator,
    turnOffLoadingActionCreator,
    turnOnLoadingActionCreator
} from "../../../../app/src/actions/commonActions";
import {
    DATA_LOADING_STATUS_LOADED,
    DATA_LOADING_STATUS_LOADING,
    WIDGET_TYPE_HIGHLIGHT
} from "../../../../app/src/Utils/Constants";
import { splash } from '@core';
import Footer from "../../../../app/src/Components/GUI/Footer/Footer";
import Menu from "../../../../app/src/Pages/Menu/Menu";
import { SECTION_URL_BY_NAME } from "../../../../app/src/Components/Routes/Location";
import useOutput from "../../../../app/src/Utils/Hooks/useOutput";
import { interops } from "../../../../app/core-framework";
import {
    TYPE_BANNER_FEATURED,
    TYPE_BANNER_LIVE_FILTERS
} from "../../../../detail/src/Pages/DetailPage/PlaylistConstants";
import { WIDGET_TYPE_BANNER, WIDGET_TYPE_GRID, WIDGET_TYPE_LIVE_V2, WIDGET_TYPE_POSTER, WIDGET_TYPE_PROGRESS } from '../../../../app/src/Components/widget_v2/contanst';



const HomeComponent = (props) => {

    const dispatch = useDispatch();
    const outputFn = useOutput();
    const { userAppController, menuAppController, guest } = props;
    const { reloadUser } = useContext(User);

    const [nextScreen, setNextScreen] = useState(null);
    const [homeData, setHomeData] = useState([]);
    const [error, setError] = useState(false);
    const [homeController, setHomeController] = useState(null);
    const [localSectionSelected, setLocalSectionSelected] = useState()

    const HOME = 'home';

    const turnOnLoading = () => {
        dispatch(turnOnLoadingActionCreator())
    };

    const turnOffLoading = () => {
        dispatch(turnOffLoadingActionCreator())
    };

    let history = useHistory();

    if (props.location.pathname !== URL_HOME) {
        history.push(URL_HOME)
    }

    let con;
    let binding;

    const handleDisplayOnBoarding = (value) => {
        dispatch(displayOnBoardingActionCreator(value))
    }

    useEffect(() => {
        !!guest && handleDisplayOnBoarding(!guest)
    }, []);


    const loadSection = (localSectionSelected) => {
        let idSection = localSectionSelected;
        if (props.location.aboutProps !== undefined) {
            idSection = props.location.aboutProps.dinamic;
        }
        return new home.events.LoadScreen(idSection);
    }

    useEffect(() => {
        turnOnLoading()
        getNextScreen(splash.controller).then((n) => {
            setNextScreen(n)
        });

        const localSectionSelected = localStorage.getItem("OTT-SectionID");
        setLocalSectionSelected(localSectionSelected)
        con = new home.controller;
        binding = con.bind(model, o => outputFn(o, output, home));

        let lwl;
        if (props.location.aboutProps === undefined && !localSectionSelected) {
            lwl = home.events.LoadDefaultScreen;
        } else {
            lwl = loadSection(localSectionSelected);
        }
        con.dispatch(lwl)
        con.dispatch(home.events.LogHomeView)

        setHomeController(con)

        return () => {
            con.unbind(binding)
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const model = (o) => {
        const formattedModel = {};
        if (o.screenWidgets) {
            const ws = [];
            const it = o.screenWidgets.iterator();
            while (it.hasNext()) {
                const a = it.next();
                let w = {};
                if (a.type?.name$ === TYPE_BANNER_FEATURED) {
                    const listItems = a.widget?.content?.toArray();
                    (a.loadState?.name$ === DATA_LOADING_STATUS_LOADING || (a.loadState?.name$ === DATA_LOADING_STATUS_LOADED && listItems.length > 0)) && (
                        w = {
                            id: a.widget?.id,
                            titulo: a.widget?.title,
                            lista: listItems,
                            background: a.widget?.backgroundImage,
                            backgroundColor: a.widget?.backgroundColor,
                            titleColor: a.widget?.textColor,
                            type: WIDGET_TYPE_HIGHLIGHT,
                            rotateTime: a.widget?.rotateTime,
                            access: a.widget?.action?.name$,
                            hasMoreContents: a.widget?.hasMoreContents,
                            loadState: a.loadState?.name$
                        }
                    )
                } else if (a.type?.name$ === TYPE_BANNER_LIVE_FILTERS) {
                    const listItems = a.widget?.widgets?.toArray();
                    (a.loadState?.name$ === DATA_LOADING_STATUS_LOADING || (a.loadState?.name$ === DATA_LOADING_STATUS_LOADED && listItems.length > 0)) && (
                        w = {
                            titulo: a.widget?.title,
                            lista: listItems,
                            background: a.widget?.backgroundImage,
                            backgroundColor: a.widget?.backgroundColor,
                            titleColor: a.widget?.textColor,
                            type: WIDGET_NAME_GROUP_WIDGET,
                            access: a.widget?.action?.name$,
                            hasMoreContents: a.widget?.hasMoreContents,
                            id: a.widget?.id,
                            loadState: a.loadState?.name$
                        }
                    )
                } else {
                    const listItems = a.widget?.content?.toArray();
                    (a.loadState?.name$ === DATA_LOADING_STATUS_LOADING || (a.loadState?.name$ === DATA_LOADING_STATUS_LOADED && listItems.length > 0)) && (
                        w = {
                            titulo: a.widget?.title,
                            lista: listItems,
                            background: a.widget?.backgroundImage,
                            backgroundColor: a.widget?.backgroundColor,
                            titleColor: a.widget?.textColor,
                            type: a.type?.name$,
                            access: a.widget?.action?.name$,
                            hasMoreContents: a.widget?.hasMoreContents,
                            id: a.widget?.id,
                            loadState: a.loadState?.name$
                        }
                    )
                }
                ws.push(w);
            }
            formattedModel.widgets = ws;

        }
        if (formattedModel?.widgets?.length > 0) {
            setHomeData(formattedModel);
        }

    }

    const output = (o) => {
        if (o.screen) {
            const next = o.screen.constructor.$metadata$.simpleName;
            setNextScreen(SECTION_URL_BY_NAME[next.toUpperCase()]);
        }

        if (o.error) {
            setError(true)
        }

        if (o.constructor.$metadata$.simpleName === ACTION_GAME) {
            const achievements = interops.toJsArray(o.actionResult.achievements);
            const rewards = interops.toJsArray(o.actionResult.rewards);
            if (!o.actionResult.hasFailed) {
                if (achievements.length > 0 || rewards.length > 0) {
                    localStorage.setItem("playingAnimationGetGoal", 1);
                    localStorage.setItem("achievements", achievements);
                    localStorage.setItem("rewards", rewards);
                }
            }
            if (reloadUser) {
                reloadUser()
            }
        }
    }

    useEffect(() => {

        if (homeController) {
            const userCon = userAppController;
            let userBinding = userCon.bind(userModel, o => outputFn(o, userOutput, user));
            userCon.dispatch(user.events.LogUserProfileView)

            return () => {
                userCon.unbind(userBinding);
            }
        }
    }, [homeController])

    const userModel = o => {
        if (o.language && homeController) {
            let lwl;
            if (props.location.aboutProps === undefined) {
                lwl = home.events.LoadDefaultScreen;
            } else {
                lwl = new home.events.LoadScreen(props.location.aboutProps.dinamic);
            }

            homeController.dispatch(lwl)
            homeController.dispatch(home.events.LogHomeView)
        }
    };

    const userOutput = o => {
    };

    const selectedVideo = (id) => {
        con?.dispatch(new home.events.LogSelectItem(id))
    }

    if (!nextScreen) {
        return <div className="loading" />;
    }

    if (nextScreen !== URL_HOME && nextScreen !== URL_LOGIN) {
        return (
            <Redirect to={nextScreen} />
        );
    }

    let defaultBackground = document.getElementById("root").style.getPropertyValue("--bannerWidgetImageColor");
    let defaultBackgroundColor = document.getElementById("root").style.getPropertyValue("--bannerWidgetBgColor");
    let defaultTitleColor = document.getElementById("root").style.getPropertyValue("--rowTitleColor");

    return (
        <>
            <Menu {...props} homeController={homeController} menuAppController={menuAppController} localSectionSelected={localSectionSelected} />
            <div className={"WithMenuContainer"}>
                <div className="Home">
                    {homeData?.widgets?.map(a => {
                        let background = a.background || defaultBackground;
                        let backgroundColor = a.backgroundColor || defaultBackgroundColor;
                        let titleColor = a.titleColor || defaultTitleColor;
                        return a.titulo ?
                            <div key={a.titulo} className={"containerWidget"}>
                                {
                                    !!a.type &&
                                        a.type !== WIDGET_TYPE_HIGHLIGHT.toUpperCase() &&
                                        a.type !== WIDGET_TYPE_GRID.toUpperCase() &&
                                        a.type !== WIDGET_TYPE_LIVE_V2.toUpperCase() &&
                                        a.type !== WIDGET_TYPE_POSTER.toUpperCase() &&
                                        a.type !== WIDGET_TYPE_PROGRESS.toUpperCase() &&
                                        a.type !== WIDGET_TYPE_BANNER.toUpperCase() ?
                                        <div className="FrameworkHomeBackgroundContainer">
                                            <div className="FrameworkHomeBackground" style={{
                                                backgroundImage: "url(" + background?.url + ")",
                                                backgroundColor: backgroundColor
                                            }}>
                                            </div>
                                        </div>
                                        : null}
                                <Playlist
                                    className="FrameworkHome"
                                    clickCallback={selectedVideo}
                                    id={a.id}
                                    hasMoreContents={a.asMoreContents}
                                    playlist={a.lista}
                                    hideSubtitle={true}
                                    titulo={a.titulo}
                                    turnOffLoading={turnOffLoading}
                                    turnOnLoading={turnOnLoading}
                                    typeBanner={a.type}
                                    access={a.access}
                                    rotateTime={a.rotateTime}
                                    titleColor={titleColor}
                                    pageLocation={HOME} />
                            </div>
                            : null;
                    })}
                    {error && <AlertNotification />}
                </div>
            </div>
            <Footer />
        </>
    );
}

export default HomeComponent;
