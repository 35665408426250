import React from "react";
import CarouselAuto from 'react-bootstrap/Carousel'
import "react-multi-carousel/lib/styles.css";
import '@detail/Pages/DetailPage/CarrouselStyle.css';

import Slide from "./slide/Slide";
import { MULTITENANT_CONSTANTS, TENANT } from "../../Resources/Multitenant/tenantConstants";
import style from './style.module.css'
import './carousel.css'


const WidgetHightligth_v2 = ({ playlist, rotateTime, setRef, getRef, defaultBanner,
    access, clickCallback }) => {

    return (
        <div className={`${style.carouselContainer} ${style.principal}`}>
            {playlist?.length &&
                <CarouselAuto
                    draggable
                    id='hightligthCarousel_v2'
                    controls={true}
                    interval={60000}
                    indicators={MULTITENANT_CONSTANTS[TENANT].showSlideHighlightIndicators}>
                    {playlist?.map((w) =>
                        <CarouselAuto.Item
                            interval={rotateTime} key={w.id}>
                            <Slide w={w} setRef={setRef} getRef={getRef} defaultBanner={defaultBanner}
                                access={access} clickCallback={clickCallback} />
                        </CarouselAuto.Item>
                    )}
                </CarouselAuto>
            }
        </div>
    )
};

export default WidgetHightligth_v2;