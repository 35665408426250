import React, {useEffect, useState} from 'react';
import ContentComponent from "../../../../../catalogue/src/Pages/Catalogo/components/ContentComponent";
import SelectedContentComponent from "../../../../../catalogue/src/Pages/Catalogo/components/SelectedContentComponent";
import NoContentComponent from "../../../../../catalogue/src/Pages/Catalogo/components/NoContentComponent";
import {Row} from "reactstrap";
import InfiniteScroll from 'react-infinite-scroll-component';
import GalleryCardComponent from "../../../../../app/src/Components/common/GalleryCardComponent";
import {IMAGE_HEIGHT, IMAGE_WIDTH} from "../../../../../catalogue/CatalogueConstants";
import VideoType from "../VideoType";
import ContentCardComponent from "../../../../../catalogue/src/Pages/ContentCardComponent";
import iconTVG from '@app/Resources/Images/icono_TVG.png';
import {evaluateItemClass} from "./utils";
import {ACCESS_PLAY, BANNER_LARGE_HEIGHT, BANNER_LARGE_WIDTH} from "../PlaylistConstants";
import {URL_PLAYER, URL_VIDEODETAIL} from "../../../../../app/src/Components/Routes/Location";
import {Link} from "react-router-dom";
import defaultBanner from '@app/Resources/Images/default_banner.png';
import {useTranslation} from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';

import './LiveWidget.css';

const LiveVerticalWidgetComponent = ({key, className, style, titulo, isSmall, typeBanner,
                                         playlist, playSpinner, access, clickCallback, hideSubtitle, titleColor}) => {

    const {t, i18n} = useTranslation();

    const getImageCard = (w) => {
        return (w.poster?.url || w.banner?.url) ?
            <img className="PlaylistItemImage"
                 src={!!w.poster
                     ? w.poster?.url:
                     w.banner?.url}
                 alt={w.title}/>
            : <img className="PlaylistItemImage" src={defaultBanner} alt={w.title}/>
    }


    return <><div className="CarouselContainer">
            <div className="PlaylistContainer" key={key}>
                <div className={"Playlist " + className} style={style}>
                    <div className="Titulo" style={{display: "flex"}}>
                        <p sstyle={{width: "auto", color: titleColor}}>{titulo}</p>
                    </div>
                    <div className="LiveGrid">
                    {playlist?.length > 0 && playlist?.map((w, i) =>
                        <div className={'PlaylistItemContainer'} key={i}>
                            <Link to={{
                                pathname: access === ACCESS_PLAY ? URL_PLAYER + "/" + w.id : URL_VIDEODETAIL + "/" + w.id,
                                state: {
                                    url: w.video?.url,
                                    type: w.video?.type,
                                    id: w.id
                                }
                            }}>
                                <div
                                    className="PlaylistItem"
                                    onClick={clickCallback ? () => clickCallback(w.id) : ''}
                                    key={w.id + "-" + w.video?.url}
                                >
                                    {getImageCard(w)}

                                    {w.tags.size !== 0 && w.tags.toArray().includes('TVG') &&

                                    <img className="iconTVG" src={iconTVG} alt={"iconTVG"}/>
                                    }

                                    {(w.poster?.url === null && w.banner?.url === null) &&
                                    <img className="PlaylistItemImage" src={defaultBanner} alt={w.title}/>
                                    }

                                    <div className="PlaylistItemTitle">{w.title}</div>
                                    <div className="PlaylistItemSubtitle">{w.subtitle}</div>
                                    {!hideSubtitle &&
                                    <>
                                        {w.episode &&
                                        <div
                                            className="PlaylistItemSubtitle">{t('ficha.episodio', {episodio: w.episode})}</div>
                                        }
                                        {!w.episode &&
                                        <div className="PlaylistItemSubtitle">{w.subtitle}</div>
                                        }
                                    </>
                                    }
                                    <div className="ButtonAccess"
                                         onClick={clickCallback ? () => clickCallback(w.id) : ''}>
                                        <div type="button" className="Access button-focused">
                                            {t('home.access')}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <VideoType subtitle={w}/>
                        </div>
                    )
                    }
                    </div>
                </div>
            </div>
        </div>
        {
            playSpinner && <div className="SpinnerContainer">
                <Spinner animation="border" variant="primary" />
            </div>
        }
</>
}

export default LiveVerticalWidgetComponent;